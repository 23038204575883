import React from "react";
import "./Home.scss";
import { Col, Container, Row } from "react-bootstrap";
import CustomButton from "../../Component/CustomButton/CustomButton";
import StakeCard from "./StakeCard";

// Importing images
import lockedValueArrow from "../../Asset/Img/locked_value_arrow.svg";
import stakeIcon from "../../Asset/Img/stake.svg";
import averageIcon from "../../Asset/Img/avrage.png";
import lockedArrowIcon from "../../Asset/Img/locked_arrow.svg";
import activeStakeArrow from "../../Asset/Img/active_stake.svg";
import averageApplyArrow from "../../Asset/Img/average_apply.svg";
import shidoMock from "../../Asset/Img/shido_mock.svg";
import desktop from "../../Asset/Img/desktop.svg";
import mobile from "../../Asset/Img/mobile.svg";
import availability from "../../Asset/Img/availability.svg";
import node from "../../Asset/Img/nodes.svg";
import decentralized from "../../Asset/Img/decentralize.svg";
import licensekey from "../../Asset/Img/license_key.svg";
import web3 from "../../Asset/Img/web3.svg";
import wallet from "../../Asset/Img/Wallet.svg";
import shidoNetwork from "../../Asset/Img/shodo_network.svg";
import shearCircle from "../../Asset/Img/share_circle.svg";
import stake from "../../Asset/Img/blur_img_without_bgnew.png";
import blueNode from "../../Asset/Img/bluenode_capital.svg";
import cls from "../../Asset/Img/cls.svg";
import coinMarketCap from "../../Asset/Img/coinmarketcap_white.svg";
import hasu from "../../Asset/Img/hasu_capital.svg";
import transak from "../../Asset/Img/transakk.svg";
import zokyoLogo from "../../Asset/Img/zokyologo.svg";

// import FooterLinkList from "./FooterLinkList";

const Home: React.FC = () => {
  const stakeCardData = [
    {
      icons: lockedArrowIcon,
      value: "$420 M",
      title: "Total Value Locked",
      rightImage: lockedValueArrow,
    },
    {
      icons: stakeIcon,
      value: "145,230",
      title: "Active Stake",
      rightImage: activeStakeArrow,
    },
    {
      icons: averageIcon,
      value: "18.5%",
      title: "Average APY",
      rightImage: averageApplyArrow,
    },
  ];

  const accessible = [
    {
      icons: desktop,
      value: "Desktop",
      title:
        "Access the Shido Staking Platform from your computer for a full-featured experience.",
    },
    {
      icons: mobile,
      value: "Mobile",
      title:
        "Manage your Shido staking on the go with our responsive mobile-friendly interface.",
    },
    {
      icons: availability,
      value: "24/7 Availability",
      title:
        "The platform is accessible 24 hours a day, 7 days a week, allowing you to stake your Shido at any time.",
    },
  ];

  const stakeCardBlockData = [
    {
      icons: licensekey,
      value: <> Secure<br /> Staking</>,
      title:
        "Your funds are protected by advanced blockchain security protocols.",
    },
    {
      icons: decentralized,
      value: <>Competitive <br /> Returns</>,
      title: "Earn up to 18% APY on your Shido coin holdings",
    },
    {
      icons: node,
      value: "Decentralized",
      title: "No intermediaries. Direct staking on the Shido blockchain,",
    },
  ];

  const ecosystemData = [
    {
      icons: shidoNetwork,
      value: "Shido Network",
      title: "Core Blockchain Infrastructure",
    },
    {
      icons: wallet,
      value: "CryptoTech Ventures",
      title: "Blockchain Investment Partner",
    },
    {
      icons: shearCircle,
      value: "Decentralized Labs",
      title: "Technology Development Partner",
    },
    {
      icons: web3,
      value: "Web3 capital",
      title: "Funding and Advisory Partner",
    },
  ];

  const backedData = [
    { img: blueNode },
    { img: cls },
    { img: coinMarketCap },
    { img: hasu },
    { img: transak },
    { img: zokyoLogo },
  ];

  return (

    <div className="landing-page">
      <section>
        <div className="earnPassive">
          <Container>
            <div className="subHeading">
              <h2>
                Earn Passive <br /> Income with Shido <br />
                Finance
              </h2>
              <p>
                Stake your Shido coins and unlock the power of decentralized
                finance. Earn attractive returns while supporting the Shido
                blockchain ecosystem.
              </p>
            </div>
            <CustomButton text="Start Staking"
              onClick={() => {
                window.open('https://stake.shido.finance');
              }} />
          </Container>
        </div>
      </section>
      <section className="space">
        <div className="stakeCards-list">
          <Container>
            <Row className="stakeMain">
              {stakeCardData.map((data, index) => (
                <Col style={{ marginBottom: "20px" }} xs={24} sm={6} lg={4} key={index}>

                  <StakeCard
                    className="stakeCard-Outer"
                    icons={data.icons}
                    value={data.value}
                    title={data.title}
                    rightImage={data.rightImage}
                  />

                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
      <section>
        <div className="accessible-main">
          <Container>
            <Row className="accessibleOuter">
              <Col xs={12} lg={7}>
                <div className="accessibleOuter_imgSec">
                  <div className="subHeading">
                    <h3 className="title">
                      Accessible from Any <br /> Device, Anytime
                    </h3>
                  </div>
                  <img className="fluid" src={shidoMock} alt="img" />
                </div>
              </Col>
              <Col xs={12} lg={5}>
                <div className="accessibleOuter_contentSec">
                  {accessible.map((data, index) => (
                    <StakeCard
                      key={index}
                      className="accessibleFeatures"
                      icons={data.icons}
                      value={data.value}
                      title={data.title}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="space">
        <div className="stakeCardsBlock-list">
          <Container>
            <Row gutter={20} className="stakeCardblock_main" >
              {stakeCardBlockData.map((data, index) => (
                <Col style={{ marginBottom: "20px" }} xs={12} md={6} lg={4} key={index}>
                  <StakeCard
                    className="stakeCardblock_main_Outer"
                    icons={data.icons}
                    value={data.value}
                    title={data.title}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
      <section>
        <div className="ecosystem">
          <Container>
            <Row className="ecosystem_main">
              <Col xs={12} lg={7}>
                <div className="subHeading">
                  <h3 className="title">
                    Shido Ecosystem <br /> Partners
                  </h3>
                </div>
                <img className="fluid" src={stake} alt="img" />
              </Col>
              <Col className="ecosystem_main_list" xs={12} lg={5}>
                {ecosystemData.map((data, index) => (
                  <StakeCard
                    key={index}
                    className="ecosystem_main_list_Card"
                    icons={data.icons}
                    value={data.value}
                    title={data.title}
                  />
                ))}
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="space">
        <div className="backed-main">
          <Container>
            <div className="backed-by">
              <div className="subHeading">
                <h3 className="title">Backed By</h3>
                <p>
                  Supported by leading venture capital firms and blockchain
                  investors
                </p>
              </div>
              <Row gutter={20}>
                {backedData.map((data, index) => (
                  <Col style={{ marginBottom: "20px" }} xs={6} lg={4} key={index}>
                    <div className="backedCard ">
                      <img className="fluid" src={data.img} alt="img" />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </div>

  );
};

export default Home;
