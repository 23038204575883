
import { Link } from 'react-router-dom';
import "./Home.scss";
import { Container } from 'react-bootstrap';
import logo from '../../Asset/Img/NewLogo.svg';
import fb from '../../Asset/Img/facebook_.svg';
import youtube from '../../Asset/Img/youtube.svg';
import discode from '../../Asset/Img/discode.svg';
import reddif from '../../Asset/Img/reddif.svg';
import insta from '../../Asset/Img/instagram.svg';
import x from '../../Asset/Img/x_.svg';
import telegram from '../../Asset/Img/telegram_.svg';

const FooterLinkList = () => {
  return (
    <>
        <footer className='landing_footer'>
            <Container>
            <div className="list-container">
            <div className="list-column products">
                <h5>Products</h5>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shido.io/blockchain">Shido Network</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shidoscan.com">Shidoscan</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shido.io/dex">Shido DEX</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://example.com/link4">Shido Ninja T2E Game</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shido.io/wallet">Shido App</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shidomarket.com">Shido Market NFT</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shido.io/shido-defi-card">Shido DeFi Card</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://ide.shidoscan.com">Shido IDE</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://governance.shidoscan.com/">Shido Governance</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://migration-portal.shidoscan.com">Shido Migration Portal</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://validator.shidoscan.com/blocks">Shido Validator App</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://go.skip.build">Shido Bridge</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shidoscan.com/validators">Shido Staking</Link></span>
                </div>
            </div>
            
            <div className="list-column information-more">
                <div className='heading'>
                    <h5>Information</h5> <h5>Contact</h5>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shido.io/terms">Terms of Service</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://coinmarketcap.com/currencies/shido-inu-new/">CoinMarketCap</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shido.io/privacy">Privacy Policy</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://www.shido.news/news">News</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://shido.io/criticalnotice/">Critical Notice</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://github.com/ShidoGlobal">GitHub</Link></span>
                </div>
                {/* <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://example.com/link7">Documentation (Shido Network)</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://example.com/link8">Email</Link></span>
                </div>
                <div className="links">
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://example.com/link9">Documentation (Shido)</Link></span>
                    <span><Link target="_blank" rel="noopener noreferrer" to="https://example.com/link10">Brandkit</Link></span>
                </div> */}
            </div>

                <div className="list-column  center-content">
                    
                    <div>
                        <address>
                            {/* <p>info@shido.io</p> */}
                            <p>Shido Global LTD.</p>
                            <p>© 2024 by Shido Global</p>
                        </address>
                    </div>
                </div>
            </div>
            <div className='footerBar'>
               <img src={logo} alt="logo"/> 
               <div className='footerBar_media'>
               <Link target="_blank" rel="noopener noreferrer" to="https://t.me/shido_tapgame_bot"><img src={telegram} alt="icon"/></Link>
               <Link target="_blank" rel="noopener noreferrer" to="https://x.com/ShidoGlobal"><img src={x} alt="icon"/></Link>
               <Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/ShidoGlobal"><img src={fb} alt="icon"/></Link>
               <Link target="_blank" rel="noopener noreferrer" to="https://www.instagram.com/shidoglobal"><img src={insta} alt="icon"/></Link>
               <Link target="_blank" rel="noopener noreferrer" to="https://www.reddit.com/r/ShidoInuOfficial"><img src={reddif} alt="icon"/></Link>
               <Link target="_blank" rel="noopener noreferrer" to="https://discord.com/invite/9zvD93q5dW"><img src={discode} alt="icon"/></Link>
               <Link target="_blank" rel="noopener noreferrer" to="https://www.youtube.com/@ShidoGlobal"><img src={youtube} alt="icon"/></Link>
               </div>
            </div>
            </Container>
        </footer>
      
    </>
  )
}

export default FooterLinkList
