import React from "react";
import "./CustomButton.scss";
// import { RightArrowBtnIcon } from "../../../Assets/Icon/svg/SvgIcons";

const CustomButton = ({
  text,
  onClick,
  className,
  disabled,
  icon,
}: {
  text?: string;
  onClick?: any;
  className?: string;
  disabled?: any;
  icon?: any;
}) => {
  return (
    <button
      disabled={disabled}
      className={`custom-button ${className ? className : ""}`}
      onClick={onClick}
    >
      {text}
      {icon && <span className="custom-button__icon">{icon}</span>}
    </button>
  );
};

export default CustomButton;
