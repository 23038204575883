// App.tsx or App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Landing/Home';
import PrimaryLayout from './Pages/PrimaryLayout/PrimaryLayout';

// Import your components


const App: React.FC = () => {
  return (
    <Router>
      <Routes>
      <Route element={<PrimaryLayout/>}>

        <Route path="/" element={<Home/>} />
      
        </Route>
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
