import React from 'react';
import "./Home.scss"


interface StakeCardProps {
  icons: any;
  value: any;   
  title: string;      
  rightImage?: any; 
  className?:string;
}

const StakeCard: React.FC<StakeCardProps> = (props) => {
  const { icons, value, title, rightImage ,className } = props;

  return (
    <div className={className}>
      <div>
        <img src={icons} alt="icon" />
        <h4>{value}</h4>
        <p>{title}</p>
      </div>
       {rightImage && <img src={rightImage} alt="img" />}
    </div>
  );
}

export default StakeCard;
