import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import Logo from "../../Asset/Img/NewLogo.svg";
import LogoMob from "../../Asset/Img/logo_small.png";
import { CloseIcon, MenuIcon, RightArrow } from "../../Asset/svg/SvgIcons";
import CustomButton from "../CustomButton/CustomButton";
import "./Header.scss";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const isHomePath = location.pathname === "/";

  // Handle scroll event to change header style
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <header className={`header ${isScrolled ? "header__scrolled" : ""}`}>
      <Navbar expand="md">
        <Container>
          {/* Logo with responsive switching */}
          <NavLink to="/" className="navbar-brand">
            <img src={Logo} className="d-none d-md-block" alt="Desktop Logo" />
            <img src={LogoMob} className="d-block d-md-none" alt="Mobile Logo" />
          </NavLink>

          <div className="header__action">
            <div className={`mob-menu ${menuOpen ? "open" : ""}`} id="basic-navbar-nav">
              <Nav className="justify-content-end flex-grow-1">
                {/* Conditional Button or Navigation Links */}
                {isHomePath ? (
                  <CustomButton
                    className="icon-btn"
                    text="Explore Shido Staking"
                    icon={<RightArrow />}
                    onClick={() => {
                      window.open('https://stake.shido.finance');
                    }}
                  />
                ) : (
                  <>
                    <NavLink to="/dashboard" className="nav-link" onClick={handleMenuToggle}>
                      Dashboard
                    </NavLink>
                    <NavLink to="/transactions" className="nav-link" onClick={handleMenuToggle}>
                      Transactions
                    </NavLink>
                  </>
                )}
              </Nav>
              {/* Mobile menu close button */}
              <button className="mob-menu__close d-block d-md-none btnTransparent" onClick={handleMenuToggle}>
                <CloseIcon />
              </button>
            </div>
            {/* Mobile menu toggle button */}
            <button className="d-block d-md-none ms-3 btnTransparent" onClick={handleMenuToggle}>
              <MenuIcon />
            </button>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
