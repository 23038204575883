import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from "../../Component/Header/Header";
import FooterLinkList from "../Landing/FooterLinkList";
import "./PrimaryLayout.scss";

// Typing the component as a functional component (React.FC)
const PrimaryLayout: React.FC = () => {
  // UseLocation returns a Location object, which TypeScript knows about by default
  const location = useLocation();

  // Check if the current path is the home path
  const isHomePath = location.pathname === "/";

  return (
    <main className={`primary-layout ${isHomePath ? "landingOuter" : ""}`}>
      <Header />
      <div className="primary-layout__inner">
        <Outlet /> {/* Render child components here */}
      </div>
      <FooterLinkList />
    </main>
  );
};

export default PrimaryLayout;
